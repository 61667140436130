<template>
    <div>
      <b-card>
        <b-col cols="12">
        
          <StorePaymentSessionTransaction
            v-if="authPermissions.includes('store-payment-session-transaction')"
          />
          <CancelPaymentSession
            v-if="authPermissions.includes('cancel-payment-session-transaction')"
          />
     
        </b-col>
      </b-card>
  
      <!-- error handler -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDisnissaibleAlert"
        @dismissed="showDisnissaibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="(values, index) in errors_back" :key="index">
            <li v-for="(value, indexVal) in values" :key="indexVal">
              {{ value }}
            </li>
          </ul>
        </div>
      </b-alert>
    </div>
  </template>
  <script>
  import CancelPaymentSession from '../../Components/OperationActions/PaymentSession/CancelPaymentSession.vue'
  import StorePaymentSessionTransaction from '../../Components/OperationActions/PaymentSession/StorePaymentSessionTransaction.vue'
  
  
  export default {
    components: {
      CancelPaymentSession,
      StorePaymentSessionTransaction,
    },
  
    computed: {
      errors_back() {
        return this.$store.state.Errors.errors_back
      },
      showDisnissaibleAlert() {
        return this.$store.state.Errors.showDismissibleAlert
      },
    },
  
    data() {
      return {
        cancleOrderStatusId: null,
      }
    },
    methods: {
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
    created() {
      this.authPermissions = this.$store.state.authPermissions
    },
  }
  </script>
    <style lang="scss">
  #vs9__listbox {
    z-index: 99999 !important;
  }
  [dir='rtl'] .vs__selected-options {
    direction: rtl !important;
  }
  .vs__selected {
    color: black !important;
  }
  .vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
    min-width: 75px !important;
  }
  </style>
    